import React from "react"

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { Helmet } from "react-helmet";

import iPhoneImage from "../images/iphone-12--product-red@2x.png"
import WachbueroLogo from '../images/Logo.svg';

export default function Home() {
  return (
    <Container>
      <CssBaseline />
      <Helmet title="Wachbüro | Ihre digitale Feuerwache" defer={false} />
      <Grid container>
        <Grid item xs={6}>
          <img src={WachbueroLogo} alt="Wachbüro Logo"/>
        </Grid>
        <Grid item xs={6}>
        </Grid>
      </Grid>
      <Grid container justify="space-between">
        <Grid item xs={12} md={7}>
          <Typography variant="h3" style={{color: "#CE2721"}}>DIGITALISIERUNG 4.0</Typography>
          <Typography variant="h1" component="h2" gutterBottom>Wir digitalisieren Ihre Feuerwache</Typography>
          <Typography variant="body1">Zettel und Stift für die Verwaltung Ihrer Feuerwache gehören der Vergangenheit an. Wir helfen Ihnen mit unserer SaaS Lösung Ihre Prozesse zu digitalisieren.</Typography>
        </Grid>

        <Grid item xs={12} md={3}>
          <img src={iPhoneImage}
            draggable={false}
            style={{height: '700px'}}
            alt="iPhone mit einem Screenshot der Wachbüro Startseite" />
        </Grid>
      </Grid>
      <Grid container xs={12} wrap="wrap-reverse">
        <Grid item xs={12} lg={6}>
          <img src={WachbueroLogo} alt="Wachbüro Logo"/>
          <Typography variant="body2">© Copyright 2020 <b>Wachbüro Software GmbH</b>.<br />All rights reserved.</Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container alignContent="center">
            <Grid item xs={4}>
              <Typography variant="h6">Produkt</Typography>

              <Typography>Funktionen</Typography>
              <Typography>Preis</Typography>
              <Typography>Was ist neu</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6">Rechtliches</Typography>

              <Typography>Nutzungsbedingungen</Typography>
              <Typography>Datenschutz</Typography>
              <Typography>GDPR</Typography>
              <Typography>DPA</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6">Unternehmen</Typography>

              <Typography>Über uns</Typography>
              <Typography>Jobs</Typography>
              <Typography>Presse</Typography>
              <Typography>Blog</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
